import React from 'react'

export default function AssetProperty({
    propKey,
    propValue
}) {
    const keys = Object.getOwnPropertyNames(propValue);
    console.log(`AssetProperty keys: ${keys}`);
    
    if (keys.indexOf('type') >= 0) {
        if (propValue['type'] === 'image') {
            return (
                <>
                <h6>{propValue['title']}</h6>
                <img src={propValue['url']} alt={propValue['title']} width="960"/>
                </>
            );
        } else {
            return (
                <div className="d-flex align-items-start">
                    <div>{`${propValue['type']}: `}</div>
                    <div>{propValue['value']}</div>
                </div>
            );
        }
    } else {
        
        
        return (
            <div className="d-flex align-items-start">
                <div>{`${propKey}: `}</div>
                <div>{propValue}</div>
            </div>
        );
    }
    
}