import React, { useEffect, useState } from 'react'
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import bg1 from '../../assets/images/bg/gallery_1.png'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import {ApplicationsService, AssetService, CreatorService} from '../../services';
import { store } from '../../state';
import AssetProperty from '../../components/asset-property';

const appSrv = new ApplicationsService();
const creatorSrv = new CreatorService();
const assetSrv = new AssetService();

export default function Work() {
    const [ asset, setAsset ] = useState(null);
    const [ metadata, setMetadata ] = useState(null);
    const [ creatorData, setCreatorData ] = useState(null);
    const [ isCreator, setIsCreator ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState()

    const { assetId } = useParams();

    useEffect(()=>{
        const retreiveAsset = async (id) => {
            const a = await assetSrv.getAsset(id);
            const promises = [];

            if (a !== null) {
                setAsset(a);

                if (a === store.state.user?.getCreatorId()) {
                    setIsCreator(true);
                }

                a.url = a.url.replace('http://192.168.4.148', 'https://createdbyme.io');
                
                promises.push(appSrv.getRemoteFile(a.url).then(md => {
                    md.image = md.image.replace('http://192.168.4.148', 'https://createdbyme.io');
                    setMetadata(md);
                    if(metadata?.properties) {
                        console.log(`metadata: ${JSON.stringify(Object.getOwnPropertyNames(metadata.properties))}`);
                    }
                }));
                promises.push(creatorSrv.getCreator(a.creatorId).then(c => setCreatorData(c)));

                await Promise.all(promises);
            }
        }

        retreiveAsset(assetId);
    }, []);

    const showTab = (tabId)=>{
        setSelectedTab(tabId);
    }
  return (
    <>
    <Navbar navlight={true}/>
    <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url("${metadata === null ? bg1 : metadata.image}")` , backgroundPosition:'bottom'}}>
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <div className="col-12">
                    { asset === null && (
                    <div className="title-heading text-center">
                        <h5 className="heading fw-semibold sub-heading text-white title-dark">Explore Art Works</h5>
                        <p className="text-white-50 para-desc mx-auto mb-0">Explore Creator's Work</p>
                    </div>
                    )}
                    { asset !== null && (
                    <div className="title-heading text-center">
                        <h3 className="heading fw-semibold sub-heading text-white title-dark">{asset.name}</h3>
                        <small className="text-white-50">[ {asset.assetId} ]</small>
                        {creatorData !== null && (
                            <h5>
                                by <Link to={`/creator-profile/${creatorData.displayName}`} className="btn btn-link primary text-white title-dark fs-5">{creatorData.displayName} </Link>
                            </h5>
                        )}
                        <h6>Collection: <Link to={`#`} className="btn btn-link primary text-white title-dark fs-5">{asset.seriesId} </Link></h6>
                        <p className="text-white-50 para-desc mx-auto mb-0">{asset.description}</p>
                    </div>
                    )}
                </div>
            </div>
        </div>
    </section>
    <div className="position-relative">            
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

    <section className="section">
        { asset === null && (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="features-absolute">
                        <div className="row justify-content-center" id="reserve-form">
                            <div className="col-xl-10 mt-lg-5">
                                <div className="card bg-white feature-top border-0 shadow rounded p-3">
                                    <h3 className="d-flex align-self-center">Work item was not found.</h3>
                                    <div className="text-center">
                                        <Link to="/explore" className="btn btn-primary rounded-md"><i className="uil uil-process mdi-spin me-1"></i> Explore Works</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        { asset != null && (
        <>
        <div className="container">
            { metadata !== null && (
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="features-absolute">
                        <div className="row justify-content-center" >
                            <div className="col-xl-10 mt-lg-5">
                                <img src={metadata.image} alt={asset.name} width="100%" />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

        </div>

        <div className="container">
            
            <div className="row justify-content-center mb-4 pb-2 mt-4">
                <div className="col filters-group-wrap">
                    <div className="filters-group">
                        <ul className="container-filter mb-0 categories-filter list-unstyled filter-options d-flex justify-content-center">
                            <li className={`list-inline-item categories position-relative text-dark ${selectedTab === null ? 'none' : ''}`} onClick={()=>showTab('none')}><i className="uil uil-file-slash"></i> Hide</li>
                            <li className={`list-inline-item categories position-relative text-dark ${selectedTab === 'all' ? 'active' : ''}`} onClick={()=>showTab('all')}><i className="uil uil-browser"></i> All</li>
                            <li className={`list-inline-item categories position-relative text-dark ${selectedTab === 'chain' ? 'active' : ''}`} onClick={()=>showTab('chain')}><i className="uil uil-link"></i> Chain Data</li>
                            <li className={`list-inline-item categories position-relative text-dark ${selectedTab === 'card' ? 'active' : ''}`} onClick={()=>showTab('card')}><i className="uil uil-comment-alt-lines"></i> Gallery Card</li>
                            <li className={`list-inline-item categories position-relative text-dark ${selectedTab === 'other' ? 'active' : ''}`} onClick={()=>showTab('other')}><i className="uil uil-grid"></i> Properties</li>
                        </ul>
                    </div>
                </div>
            </div>

    
        </div>
        {(selectedTab === 'chain' || selectedTab === 'all') && (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    
                    <div className="row justify-content-center" >
                        <div className="col-xl-10 mt-lg-5">
                            <div className="card bg-white feature-top border-0 shadow rounded p-3">
                                <h3>Block Chain Data</h3>
                                <h5>COMING SOON</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        )}
        {(selectedTab === 'card' || selectedTab === 'all') && (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    
                    <div className="row justify-content-center" >
                        <div className="col-xl-10 mt-lg-5">
                            <div className="card bg-white feature-top border-0 shadow rounded p-3">
                                <h3>Gallery Card</h3>
                                <h5>COMING SOON</h5>
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="">
                                            <div className="row justify-content-center" id="reserve-form">
                                                <div className="col-xl-10 mt-lg-5">
                                                    <div className="card bg-white feature-top border-0 shadow rounded p-3">
                                                        
                                                        <h6>Place holder for generating gallery card</h6>
                                                        <div style={{ background: 'white', padding: '16px', height: "auto", margin: "0 auto", maxWidth:96, width: "100%" }}>
                                                            <QRCode
                                                                size={256}
                                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                value={`http://192.168.5.220:3000/work/${asset.assetId}`}
                                                                viewBox={`0 0 256 256`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
        )}
        {(selectedTab === 'other' || selectedTab === 'all') && (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    
                    <div className="row justify-content-center" >
                        <div className="col-xl-10 mt-lg-5">
                            <div className="card bg-white feature-top border-0 shadow rounded p-3"> 
                                <h3>Other Properties</h3>
                                {metadata?.properties && Object.getOwnPropertyNames(metadata.properties).map((key, idx) => {
                                    return <AssetProperty key={`property_${idx}`} propKey={key} propValue={metadata.properties[key]} />
                                })}
                                {(metadata.properties === null) && (
                                    <h5>none</h5>
                                )}
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        )}
        </>
        )}
        <div className="container">
            <div className="row justify-content-center mt-4">
                <div className="col">
                    <div className="text-center">
                        <Link to="#" className="btn btn-primary rounded-md"><i className="uil uil-process mdi-spin me-1"></i> Load More</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <Footer/>
    </>
  )
}
